import React from "react";
import GraphqlWrapper from "GraphQL/GraphqlWrapper";
import GridPropsQuery from "../lib/AgGrid/GridPropsQuery";
import EpiImportsTable from "./EpiImportsTable";

const ProductUpdatesApp = props => (
    <div>
      <h2>Product Updates Dashboard</h2>
      <div>
        {
          <GraphqlWrapper>
            <GridPropsQuery
              render={({ contractCodes, currentUser }) => (
                <section className="epi_imports_section">
                  <EpiImportsTable contractCodes={contractCodes} currentUser={currentUser} />
                </section>
              )}
            />
          </GraphqlWrapper>
        }
      </div>
    </div>
);

export default ProductUpdatesApp; 